<template>
  <v-container fluid>
    <v-row>
      <v-col
        v-if="contadores.preImportados"
        class="pt-0 tief-three-column-resp"
        md="3"
        sm="6"
        cols="12"
      >
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-card
              class="mt-0"
              elevation="5"
              v-bind="attrs"
              v-on="on"
            >
              <v-list-item
                class="pl-1 pr-0"
                two-line
              >
                <v-list-item-avatar
                  class="my-0 mr-2"
                  tile
                  width="30"
                  height="82"
                >
                  <v-icon
                    size="45"
                    color="green"
                  >
                    mdi-check
                  </v-icon>
                </v-list-item-avatar>
                <v-list-item-content class="mr-1">
                  <v-list-item-title class="text-h5 mb-0 f-size-tief">
                    Linhas pré-importadas
                  </v-list-item-title>
                  <div class="text-overline mb-1">
                    {{ contadores.preImportados | parseNumberToIntegerBR }}
                  </div>
                </v-list-item-content>
              </v-list-item>
            </v-card>
          </template>
          Linhas inseridas com sucesso na pré-importação
        </v-tooltip>
      </v-col>
      <v-col
        v-if="contadores.errosPreImportacao"
        class="pt-0 tief-three-column-resp"
        md="3"
        sm="6"
        cols="12"
      >
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-card
              class="mt-0"
              elevation="5"
              v-bind="attrs"
              v-on="on"
            >
              <v-list-item
                class="pl-1 pr-0"
                two-line
              >
                <v-list-item-avatar
                  class="my-0 mr-2"
                  tile
                  width="30"
                  height="82"
                >
                  <v-icon
                    size="45"
                    color="#FB8C00"
                  >
                    mdi-exclamation
                  </v-icon>
                </v-list-item-avatar>
                <v-list-item-content class="mr-1">
                  <v-list-item-title class="text-h5 mb-0 f-size-tief">
                    Alertas de validação
                  </v-list-item-title>
                  <div class="text-overline mb-1">
                    {{ contadores.errosPreImportacao | parseNumberToIntegerBR }}
                  </div>
                </v-list-item-content>
              </v-list-item>
            </v-card>
          </template>
          Alertas de validação da pré-importação
        </v-tooltip>
      </v-col>
      <v-col
        v-if="naoImportadas"
        class="pt-0 tief-three-column-resp"
        md="3"
        sm="6"
        cols="12"
      >
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-card
              class="mt-0"
              elevation="5"
              v-bind="attrs"
              v-on="on"
            >
              <v-list-item
                class="pl-1 pr-0"
                two-line
              >
                <v-list-item-avatar
                  class="my-0 mr-2"
                  tile
                  width="30"
                  height="82"
                >
                  <v-icon
                    size="45"
                    color="red"
                  >
                    mdi-alert
                  </v-icon>
                </v-list-item-avatar>
                <v-list-item-content class="mr-1">
                  <v-list-item-title class="text-h5 mb-0 f-size-tief">
                    Linhas não importadas
                  </v-list-item-title>
                  <div class="text-overline mb-1">
                    {{ naoImportadas | parseNumberToIntegerBR }}
                  </div>
                </v-list-item-content>
              </v-list-item>
            </v-card>
          </template>
          Linhas não importadas
        </v-tooltip>
      </v-col>
      <v-col
        v-if="contadores.importados"
        class="pt-0 tief-three-column-resp"
        md="3"
        sm="6"
        cols="12"
      >
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-card
              class="mt-0"
              elevation="5"
              v-bind="attrs"
              v-on="on"
            >
              <v-list-item
                class="pl-1 pr-0"
                two-line
              >
                <v-list-item-avatar
                  class="my-0 mr-2"
                  tile
                  width="30"
                  height="82"
                >
                  <v-icon
                    size="45"
                    color="green"
                  >
                    mdi-check
                  </v-icon>
                </v-list-item-avatar>
                <v-list-item-content class="mr-1">
                  <v-list-item-title class="text-h5 mb-0 f-size-tief">
                    Linhas importadas
                  </v-list-item-title>
                  <div class="text-overline mb-1">
                    {{ contadores.importados | parseNumberToIntegerBR }}
                  </div>
                </v-list-item-content>
              </v-list-item>
            </v-card>
          </template>
          Linhas inseridas com sucesso na importação
        </v-tooltip>
      </v-col>
      <v-col
        cols="12"
        class="px-0 pt-0"
        id="importacao-bdgd-table-desc-ent"
      >
        <!-- <v-layout column style="max-height: 90vh">
          <v-flex md6 style="overflow: auto"> -->
        <v-data-table
          class="table-import-entity-files"
          :headers="headers"
          :items="arquivos"
          :mobile-breakpoint="800"
          hide-default-footer
          disable-pagination
        >
          <template v-slot:[`item.arquivo.arquivo`]="{ item }">
            <div id="importacao-bdgd-column-df">
              <v-tooltip
                bottom
                v-if="item.arquivo.status != 'EXPIRADO'"
              >
                <template v-slot:activator="{ on, attrs }">
                  <div
                    class="clickable-item"
                    @click="baixarArquivo(item.arquivo)"
                    style="display: inline !important"
                    v-bind="attrs"
                    v-on="on"
                  >
                    {{ item.arquivo.arquivo }}
                    <v-progress-circular
                      v-if="loadings.includes(item.arquivo.id)"
                      size="20"
                      indeterminate
                      color="rgba(0, 0, 0, 0.87)"
                    />
                  </div>
                </template>
                Clique aqui para baixar o arquivo {{ item.arquivo.arquivo }}
              </v-tooltip>
              <v-tooltip
                bottom
                v-else
              >
                <template v-slot:activator="{ on, attrs }">
                  <div
                    class="not-clickable-item"
                    style="display: inline !important"
                    v-bind="attrs"
                    v-on="on"
                  >
                    {{ item.arquivo.arquivo }}
                  </div>
                </template>
                Esse arquivo foi expirado e não pode ser baixado
              </v-tooltip>
            </div>
          </template>
          <template v-slot:[`item.status_pre`]="{ item }">
            <general-status :status="item.status_pre" />
          </template>
          <template v-slot:[`item.status_importacao`]="{ item }">
            <general-status :status="item.status_importacao" />
          </template>
          <template v-slot:footer>
            <div class="table-iet-footer ml-2 pt-4">
              {{ arquivos.length }}
              {{ arquivos.length > 1 ? 'arquivos' : 'arquivo' }}
            </div>
          </template>
        </v-data-table>
        <!-- </v-flex>
        </v-layout> -->
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import ImportacoesBdgdService from '@/services/ImportacoesBdgdService';

export default {
  components: {
    GeneralStatus: () => import('@/components/general/GeneralStatus.vue')
  },

  props: {
    entidade: {
      type: String,
      required: true
    },
    arquivos: {
      type: Array,
      required: true
    },
    contadores: {
      type: Object,
      required: true
    }
  },

  data: () => ({
    headers: [
      {
        text: 'Nome do arquivo',
        value: 'arquivo.arquivo'
      },
      {
        text: 'Status da pré-importação',
        value: 'status_pre'
      },
      {
        text: 'Status da importação',
        value: 'status_importacao'
      }
    ],
    loadings: []
  }),

  computed: {
    totalArquivos() {
      return this.arquivos.length;
    },
    totalAguardandoPre() {
      return this.contabilizaStatus('status_pre', 'aguardando');
    },
    totalExecutandoPre() {
      return this.contabilizaStatus('status_pre', 'executando');
    },
    totalFalhaPre() {
      return this.contabilizaStatus('status_pre', 'falha');
    },
    totalSucessoPre() {
      return this.contabilizaStatus('status_pre', 'sucesso');
    },
    totalAguardandoImportacao() {
      return this.contabilizaStatus('status_importacao', 'aguardando');
    },
    totalExecutandoImportacao() {
      return this.contabilizaStatus('status_importacao', 'executando');
    },
    totalFalhaImportacao() {
      return this.contabilizaStatus('status_importacao', 'falha');
    },
    totalSucessoImportacao() {
      return this.contabilizaStatus('status_importacao', 'sucesso');
    },
    naoImportadas() {
      if (this.totalAguardandoImportacao || this.totalExecutandoImportacao) {
        return 0;
      }
      let naoImportadas =
        this.contadores.preImportados - this.contadores.importados;
      return naoImportadas < 0 ? 0 : naoImportadas;
    }
  },

  methods: {
    contabilizaStatus(tipo, status) {
      return this.arquivos.filter(
        (arquivo) => arquivo[tipo].toLowerCase() === status.toLowerCase()
      ).length;
    },

    baixarArquivo(infoArquivo) {
      this.loadings.push(infoArquivo.id);

      ImportacoesBdgdService.baixarArquivo(infoArquivo.id)
        .then((res) => {
          const url = window.URL.createObjectURL(new Blob([res.data]));
          const link = document.createElement('a');
          link.href = url;
          let nomeArquivo = this.normalizeNomeArquivo(infoArquivo.arquivo);
          link.setAttribute('download', nomeArquivo);
          document.body.appendChild(link);
          link.click();
        })
        .catch(() => {
          this.$toast.error(
            `Falha no download do arquivo ${infoArquivo.arquivo}.`,
            '',
            { position: 'topRight' }
          );
        })
        .finally(
          () =>
            (this.loadings = this.loadings.filter((x) => x != infoArquivo.id))
        );
    },

    normalizeNomeArquivo(text) {
      let arrText = text.split('.');
      return `${arrText[0]}-${arrText[1]}.zip`;
    }
  }
};
</script>

<style>
.f-size-tief {
  font-size: 13.5px !important;
}

.table-import-entity-files th,
.table-iet-footer {
  font-weight: bold !important;
  font-size: 14px !important;
}

.table-iet-footer {
  border-top: thin solid rgba(0, 0, 0, 0.12);
}

.tief-three-column-resp {
  min-width: 230px !important;
}

@media screen and (max-width: 625px) {
  .tief-three-column-resp {
    padding: 0 !important;
  }
}
</style>
