var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":""}},[_c('v-row',[(_vm.contadores.preImportados)?_c('v-col',{staticClass:"pt-0 tief-three-column-resp",attrs:{"md":"3","sm":"6","cols":"12"}},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-card',_vm._g(_vm._b({staticClass:"mt-0",attrs:{"elevation":"5"}},'v-card',attrs,false),on),[_c('v-list-item',{staticClass:"pl-1 pr-0",attrs:{"two-line":""}},[_c('v-list-item-avatar',{staticClass:"my-0 mr-2",attrs:{"tile":"","width":"30","height":"82"}},[_c('v-icon',{attrs:{"size":"45","color":"green"}},[_vm._v(" mdi-check ")])],1),_c('v-list-item-content',{staticClass:"mr-1"},[_c('v-list-item-title',{staticClass:"text-h5 mb-0 f-size-tief"},[_vm._v(" Linhas pré-importadas ")]),_c('div',{staticClass:"text-overline mb-1"},[_vm._v(" "+_vm._s(_vm._f("parseNumberToIntegerBR")(_vm.contadores.preImportados))+" ")])],1)],1)],1)]}}],null,false,2410766204)},[_vm._v(" Linhas inseridas com sucesso na pré-importação ")])],1):_vm._e(),(_vm.contadores.errosPreImportacao)?_c('v-col',{staticClass:"pt-0 tief-three-column-resp",attrs:{"md":"3","sm":"6","cols":"12"}},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-card',_vm._g(_vm._b({staticClass:"mt-0",attrs:{"elevation":"5"}},'v-card',attrs,false),on),[_c('v-list-item',{staticClass:"pl-1 pr-0",attrs:{"two-line":""}},[_c('v-list-item-avatar',{staticClass:"my-0 mr-2",attrs:{"tile":"","width":"30","height":"82"}},[_c('v-icon',{attrs:{"size":"45","color":"#FB8C00"}},[_vm._v(" mdi-exclamation ")])],1),_c('v-list-item-content',{staticClass:"mr-1"},[_c('v-list-item-title',{staticClass:"text-h5 mb-0 f-size-tief"},[_vm._v(" Alertas de validação ")]),_c('div',{staticClass:"text-overline mb-1"},[_vm._v(" "+_vm._s(_vm._f("parseNumberToIntegerBR")(_vm.contadores.errosPreImportacao))+" ")])],1)],1)],1)]}}],null,false,3829231854)},[_vm._v(" Alertas de validação da pré-importação ")])],1):_vm._e(),(_vm.naoImportadas)?_c('v-col',{staticClass:"pt-0 tief-three-column-resp",attrs:{"md":"3","sm":"6","cols":"12"}},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-card',_vm._g(_vm._b({staticClass:"mt-0",attrs:{"elevation":"5"}},'v-card',attrs,false),on),[_c('v-list-item',{staticClass:"pl-1 pr-0",attrs:{"two-line":""}},[_c('v-list-item-avatar',{staticClass:"my-0 mr-2",attrs:{"tile":"","width":"30","height":"82"}},[_c('v-icon',{attrs:{"size":"45","color":"red"}},[_vm._v(" mdi-alert ")])],1),_c('v-list-item-content',{staticClass:"mr-1"},[_c('v-list-item-title',{staticClass:"text-h5 mb-0 f-size-tief"},[_vm._v(" Linhas não importadas ")]),_c('div',{staticClass:"text-overline mb-1"},[_vm._v(" "+_vm._s(_vm._f("parseNumberToIntegerBR")(_vm.naoImportadas))+" ")])],1)],1)],1)]}}],null,false,2306557223)},[_vm._v(" Linhas não importadas ")])],1):_vm._e(),(_vm.contadores.importados)?_c('v-col',{staticClass:"pt-0 tief-three-column-resp",attrs:{"md":"3","sm":"6","cols":"12"}},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-card',_vm._g(_vm._b({staticClass:"mt-0",attrs:{"elevation":"5"}},'v-card',attrs,false),on),[_c('v-list-item',{staticClass:"pl-1 pr-0",attrs:{"two-line":""}},[_c('v-list-item-avatar',{staticClass:"my-0 mr-2",attrs:{"tile":"","width":"30","height":"82"}},[_c('v-icon',{attrs:{"size":"45","color":"green"}},[_vm._v(" mdi-check ")])],1),_c('v-list-item-content',{staticClass:"mr-1"},[_c('v-list-item-title',{staticClass:"text-h5 mb-0 f-size-tief"},[_vm._v(" Linhas importadas ")]),_c('div',{staticClass:"text-overline mb-1"},[_vm._v(" "+_vm._s(_vm._f("parseNumberToIntegerBR")(_vm.contadores.importados))+" ")])],1)],1)],1)]}}],null,false,2247104445)},[_vm._v(" Linhas inseridas com sucesso na importação ")])],1):_vm._e(),_c('v-col',{staticClass:"px-0 pt-0",attrs:{"cols":"12","id":"importacao-bdgd-table-desc-ent"}},[_c('v-data-table',{staticClass:"table-import-entity-files",attrs:{"headers":_vm.headers,"items":_vm.arquivos,"mobile-breakpoint":800,"hide-default-footer":"","disable-pagination":""},scopedSlots:_vm._u([{key:"item.arquivo.arquivo",fn:function(ref){
var item = ref.item;
return [_c('div',{attrs:{"id":"importacao-bdgd-column-df"}},[(item.arquivo.status != 'EXPIRADO')?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('div',_vm._g(_vm._b({staticClass:"clickable-item",staticStyle:{"display":"inline !important"},on:{"click":function($event){return _vm.baixarArquivo(item.arquivo)}}},'div',attrs,false),on),[_vm._v(" "+_vm._s(item.arquivo.arquivo)+" "),(_vm.loadings.includes(item.arquivo.id))?_c('v-progress-circular',{attrs:{"size":"20","indeterminate":"","color":"rgba(0, 0, 0, 0.87)"}}):_vm._e()],1)]}}],null,true)},[_vm._v(" Clique aqui para baixar o arquivo "+_vm._s(item.arquivo.arquivo)+" ")]):_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('div',_vm._g(_vm._b({staticClass:"not-clickable-item",staticStyle:{"display":"inline !important"}},'div',attrs,false),on),[_vm._v(" "+_vm._s(item.arquivo.arquivo)+" ")])]}}],null,true)},[_vm._v(" Esse arquivo foi expirado e não pode ser baixado ")])],1)]}},{key:"item.status_pre",fn:function(ref){
var item = ref.item;
return [_c('general-status',{attrs:{"status":item.status_pre}})]}},{key:"item.status_importacao",fn:function(ref){
var item = ref.item;
return [_c('general-status',{attrs:{"status":item.status_importacao}})]}},{key:"footer",fn:function(){return [_c('div',{staticClass:"table-iet-footer ml-2 pt-4"},[_vm._v(" "+_vm._s(_vm.arquivos.length)+" "+_vm._s(_vm.arquivos.length > 1 ? 'arquivos' : 'arquivo')+" ")])]},proxy:true}],null,true)})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }